<template>
  <div class="success-content box-shadow-default">
    <div class="flex success-body">
      <div class="icon">
        <i class="el-icon-check"></i>
      </div>
      <div class="text">
        <span class="big-black">您的预约申请已提交！</span>
        <span>
          预约编号:
          <span class="c-orange">{{ data.yybh }}</span>
        </span>
        <span class="small-black">
          预约事项:
          <span class="c-orange">{{ data.yysx }}</span>
        </span>
        <span class="small-black">
          预约网点:
          <span class="c-orange">{{ data.bsdtTitle }}</span>
        </span>
        <span class="small-black">
          受理地点:
          <span class="c-orange">{{ data.yybsdtdz }}{{ data.ckh }}</span>
        </span>
        <span class="small-black">
          网点电话:
          <span class="c-orange">{{ data.bsdtdh }}</span>
        </span>
        <span class="small-black">
          预约时间:
          <span class="c-orange">{{ data.yysj }} {{ data.sjd }}</span>
        </span>
        <span class="small-black">
          预约排号:
          <span class="c-orange">{{ data.successNum }}号</span>
        </span>
        <span class="small-black m-t-10" v-if="!data.extraMessage">
          <span class="c-red"
            >温馨提示： 请用户到不动产登记中心之后，先到预审负责人席验证资料是否完善。(材料可在办事指南中查看)</span
          >
        </span>
        <span class="small-black m-t-10" v-if="data.extraMessage">
          <span class="c-red">温馨提示： {{ data.extraMessage }}</span>
        </span>
      </div>
    </div>
    <div class="button-content text-center">
      <el-button type="primary" @click="showMyAppointment">查看预约</el-button>
      <el-button type="primary" @click="finish">完成</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'appointment-success',
  props: {
    data: {
      type: Object
    }
  },
  methods: {
    finish() {
      this.$router.go(-1);
    },
    showMyAppointment() {
      this.$router.push({
        name: 'user-center',
        params: { page: 'appointment' }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.m-t-10 {
  margin-top: 20px !important;
}
.success-content {
  height: 448px;
  width: 100%;
  margin-top: 20px;
  background-color: #ffffff;
  padding: 110px 0 20px 0;
  .success-body {
    width: 100%;
    .icon {
      width: 250px;
      text-align: right;
    }
    .text {
      margin-left: 20px;
      width: 600px;
      display: flex;
      flex-direction: column;
      .big-black {
        margin-bottom: 20px;
        font-size: 20px;
        font-weight: bold;
      }
      .small-black {
        margin-top: 10px;
        font-size: 14px;
      }
    }
  }
  .button-content {
    margin-top: 50px;
  }
}
i {
  display: inline-block;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  line-height: 60px;
  font-size: 40px;
  background: #67c23a;
  text-align: center;
  color: white;
}
</style>
