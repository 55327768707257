<template>
  <div>
    <appointment-steps v-if="!this.$hasConstruction('IEBDC:SY:WSYY:WYYY:SFZJ')" :stepsData="stepsData" :activeStep="activeStep" :currentLabel="currentLabel">
      <template slot="content">
        <div v-if="activeStep === 0">
          <readInstructions @next="next" />
        </div>
        <div v-if="activeStep === 1">
          <appointmentInfo @last="last" :userData="userData" @next="next" @sendData="receiveAppointmentData" />
        </div>
        <div v-if="activeStep === 2">
          <appointmentSuccess :data="appointmentData" />
        </div>
      </template>
    </appointment-steps>
    <!--  后台配置权限该功能是否在建  -->
    <no-data v-else :data="dataNo"></no-data>
  </div>
</template>

<script>
import readInstructions from './pages/read-instructions';
import appointmentInfo from './pages/appointment-info';
import appointmentSuccess from './pages/appointment-success';
import AppointmentSteps from '@/pages/iebdc/components/appointment-steps/index';
import hasConstruction from '@iebdc/mixin/hasConstruction';
export default {
  name: 'serviceAppointment',
  mixins: [hasConstruction],
  components: {
    AppointmentSteps,
    readInstructions,
    appointmentInfo,
    appointmentSuccess
  },
  data() {
    return {
      dataNo:{
        src: require('@/pages/iebdc/assets/images/nodata@2x.png'),
        imgWidth: '198px',
        imgHeight: '154px',
        marginTop: '-108px',
        title: '敬请期待',
        description: '正在建设中...',
      },
      // 激活步骤
      activeStep: 0,
      // 预约步骤
      stepsData: [
        {
          title: '阅读须知'
        },
        {
          title: '预约信息'
        },
        {
          title: '完成'
        }
      ],
      currentLabel: '我要预约',
      userData: {},
      // 预约信息
      appointmentData: {}
    };
  },
  methods: {
    // 下一步
    next(activeStep) {
      this.activeStep = activeStep;
    },
    // 上一步
    last(lastStep) {
      this.activeStep = lastStep;
    },
    // 接受预约信息
    receiveAppointmentData(data) {
      this.appointmentData = { ...data };
    }
  }
};
</script>

<style lang="scss" scoped>
.appointment-warp {
  .wrap-left {
    width: 135px;
    height: auto;
    float: left;
  }
  .wrap-right {
    height: auto;
    margin-left: 135px;
  }
}
</style>
