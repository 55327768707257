<template>
  <div class="appointment-info">
    <div class="tip flex-space-between" v-if="showTip">
      <span>提示：只能次日起至5个工作日内，同一天内同一个人只能预约一次。若遇特殊情况，登记中心工作人员会及时打电话联系。</span>
      <i class="el-icon-close" @click="showTip = false"></i>
    </div>
    <div class="info-wrap box-shadow-default">
      <el-form ref="form" label-width="140px" style="display: flow-root;" :model="formData" :rules="rules">
        <div class="header-tip">预约人信息</div>
        <el-row :gutter="20">
          <el-col :span="11">
            <el-form-item label="姓名" prop="yyyhmc">
              <el-input v-model="formData.yyyhmc"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="身份证号" prop="zjh">
              <el-input v-model="formData.zjh"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="11">
            <el-form-item label="联系电话" prop="lxdh">
              <el-input v-model="formData.lxdh"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="header-tip">预约网点</div>
        <el-row :gutter="20">
          <el-col :span="11">
            <el-form-item label="预约网点" prop="yywd">
              <el-input disabled v-model="formData.yywd"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="网点地址" prop="yybsdtdz">
              <el-input disabled v-model="formData.yybsdtdz"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="11">
            <el-form-item label="网点电话">
              <el-input disabled v-model="formData.bsdtdh"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="header-tip">预约信息</div>
        <el-row :gutter="20">
          <el-col :span="11">
            <el-form-item label="预约日期" prop="yysj">
              <el-select v-model="formData.yysj" @change="getAppointmentCount">
                <el-option v-for="item in appointmentDate" :key="item" :value="item" :label="item"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="时间段" prop="sjd">
              <el-select v-model="formData.sjd" @change="getAppointmentCount">
                <el-option v-for="item in appointmentTimes" :key="item.id" :value="item.sjqj" :label="item.sjqj"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="11">
            <el-form-item label="预约业务类型" prop="yyywlx">
              <el-select v-model="formData.yyywlx" @change="getAppointmentCount">
                <el-option v-for="item in appointmentBusiness" :key="item.id" :value="item.name" :label="item.name"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="预约事项" prop="yyFlowId">
              <el-cascader
                :key="matterKey"
                :disabled="disabled"
                :options="appointmentMatter"
                :props="appointmentMatterProps"
                v-model="formData.yyFlowIds"
                @change="getFlowId"
                :show-all-levels="false"
              >
              </el-cascader>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="hasPermission('IEBDC:TXSQB:CQZH')">
          <el-col :span="11">
            <el-button @click="add" type="primary">添加不动产权证号</el-button>
          </el-col>
        </el-row>
        <div v-for="(item, index) in formData.yyzh" :key="index">
          <certificate-template
            v-if="item !== 'hidden'"
            v-model="formData.yyzh[index]"
            @deleteRow="deleteRow(index)"
            :disabledBtn="disabledBtn"
            :flexWidth="11"
            :tempalteList="template"
            :deleteBtn="hasPermission('IEBDC:TXSQB:CQZH')"
          >
          </certificate-template>
        </div>
        <el-row :gutter="20">
          <el-col :span="11">
            <el-form-item label="已预约数">
              <el-input disabled v-model="formData.yyysl"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="剩余可预约数">
              <el-input disabled v-model="formData.kyysl"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="11">
            <el-form-item label="手机号" prop="lxdh">
              <el-input v-model="formData.lxdh"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="手机验证" prop="authCode" v-if="hasPermission('IEBDC:GNXS:DXYZ')">
              <phone-code v-model="formData.authCode" :phone="formData.lxdh" :disabled="getCodeBtnDisabled"></phone-code>
            </el-form-item>
            <el-form-item label="验证码" prop="code" v-else>
              <div class="flex">
                <div style="width: 35%">
                  <el-input placeholder="验证码" v-model="formData.code"></el-input>
                </div>
                <div class="pointer code">
                  <div class="pointer code" @click="refreshCode">
                    <s-identify :identifyCode="identifyCode"></s-identify>
                  </div>
                </div>
                <div @click="refreshCode" class="pointer link" style="line-height: 40px">
                  换一张
                </div>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-show="showMatter">
          <el-col :span="22">
            <matterInfo ref="matter" />
          </el-col>
        </el-row>
        <div class="text-center margin-large">
          <el-button type="primary" @click="last">上一步</el-button>
          <el-button type="primary" @click="submit">提交</el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import matterInfo from '../component/matter-info';
import hasPermission from '@/pages/iebdc/mixin/hasPermission';
import { zxTemplate } from '@public/zsMould';
import { localDataUser } from '@iebdc/utils/local-data';
import { validateName, validateZjh } from 'iebdc/utils/validate';
import {
  bsdtDetail,
  getSystemTime,
  getYyysl,
  getSjdByOfficeCode,
  getYyxxConfigByDjjg,
  getWindowCode,
  saveAppointment
} from 'iebdc/api/service-appointment';
import { mapGetters } from 'vuex';
import SIdentify from '@/pages/iebdc/views/login/component/identify';
export default {
  name: 'appointment-info',
  mixins: [hasPermission],
  components: {
    matterInfo,
    SIdentify
  },
  data() {
    const validatePhone = async (rule, value, callback) => {
      const re = /^((0\d{2,3}-\d{7,8})|((13[0-9])|(14[5,7,9])|(15[^4])|(18[0-9])|(17[0,1,3,5,6,7,8]))\d{8})$/g;
      if (!value) {
        this.getCodeBtnDisabled = true;
        return callback(new Error('请输入电话号码'));
      }
      if (!re.test(value)) {
        this.getCodeBtnDisabled = true;
        return callback(new Error('请输入合法的电话号码'));
      }
      this.getCodeBtnDisabled = false;
      callback();
    };
    // 验证码自定义验证规则
    const validateVerifycode = (rule, value, callback) => {
      if (this.noInputCode) {
        let newVal = value.toLowerCase();
        let identifyStr = this.identifyCode.toLowerCase();
        if (newVal === '') {
          callback(new Error('请输入验证码'));
        } else if (newVal !== identifyStr) {
          callback(new Error('验证码不正确!'));
        } else {
          callback();
        }
      } else {
        callback(new Error('验证码不正确!'));
      }
    };
    this.rules = {
      yyyhmc: [{ required: true, validator: validateName, trigger: 'change' }],
      zjh: [{ required: true, validator: validateZjh(), trigger: 'change' }],
      yysj: [{ required: true, message: '请选择预约日期', trigger: 'change' }],
      sjd: [{ required: true, message: '请选择预约时间', trigger: 'change' }],
      yyywlx: [{ required: true, message: '请选择业务类型', trigger: 'change' }],
      yyFlowId: [{ required: true, message: '请选择事项', trigger: 'change' }],
      lxdh: [{ required: true, validator: validatePhone, trigger: 'change' }],
      authCode: [{ required: true, message: '请填写验证码', trigger: 'change' }],
      code: [{ required: true, validator: validateVerifycode, trigger: 'change' }]
    };
    return {
      appointmentMatterProps: {
        label: 'name',
        value: 'id',
        children: 'flowSubDefConfigs'
      },
      matterKey: 0, //解决动态切换options时，在新的options无法找到用户选中的值进而导致错误问题
      showTip: true,
      noInputCode: 0, //解决直接提交，图片验证码不提示并且控制台报错问题
      copyUserData: null, //传给
      template: zxTemplate,
      getCodeBtnDisabled: true,
      showMatter: false,
      identifyCodes: '1234567890ABCDEFGHIGKLMNoPQRSTUVWXYZ',
      identifyCode: '',
      formData: {
        yyyhmc: '',
        zjh: '',
        yysj: '',
        sjd: '',
        lxdh: '',
        yyywlx: '',
        yyFlowId: '',
        yyzh: [''],
        yyysl: '',
        kyysl: '',
        authCode: '',
        yywd: '',
        bsdtdh: '',
        yybsdtdz: ''
      },
      disabled: true,
      appointmentDate: [], // 预约日期
      appointmentTimes: [], // 预约时间
      appointmentCount: [], // 预约次数
      cacheYyyslData: {}, // 保存数据
      appointmentBusiness: [], // 预约业务
      appointmentMatter: [], // 预约事项
      appointmentBranch: [] // 预约网点
    };
  },
  computed: {
    ...mapGetters(['selectedBsdtCode', 'selectedOrganizationsCode', 'selectedOrganizationsTitle', 'selectedBsdtTitle']),
    yyywlx() {
      return this.formData.yyywlx;
    },
    disabledBtn() {
      const yyzh = this.formData.yyzh.filter((item) => item !== 'hidden');
      return yyzh.length <= 1;
    }
  },
  watch: {
    yyywlx(n) {
      if (n) {
        this.disabled = false;
        // 获取预约事项
        ++this.matterKey;
        this.appointmentMatter = this.appointmentBusiness.find((item) => item.name === n).yyxxConfigList;
        this.appointmentMatter.map((item) => {
          item.flowSubDefConfigs.map((flow) => {
            //字典值转换
            const sf = this.$store.getters.getLabelDicByTypeAndValue('是否', flow.sfkyy);
            if (sf === '否') {
              this.$set(flow, 'disabled', true);
            }
          });
        });
      } else {
        this.disabled = true;
      }
      // 重置预约事项
      this.formData.yyFlowIds = [];
      this.formData.yyFlowId = '';
    },
    selectedBsdtTitle() {
      this.init();
    },
    //解决直接提交，图片验证码不提示并且控制台报错问题
    'formData.code': function() {
      this.noInputCode++;
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      // 验证码初始化
      this.getUserInfo();
      this.getNewVerCode();
      this.getBsdtDetail();
      this.getYyslAndYysj();
      this.getSjdByOfficeCode();
      this.getYyxxConfigByDjjg();
      this.formData.yywd = this.selectedBsdtTitle;
    },
    //用户基本信息
    getUserInfo() {
      this.copyUserData = JSON.parse(JSON.stringify(localDataUser.get()));
      // console.log(this.copyUserData,'用户基本信息');
      //初始化用户信息
      if (this.copyUserData.userType === 'gr') {
        this.formData.yyyhmc = this.copyUserData.realName;
        this.formData.zjh = this.copyUserData.zjh;
        this.formData.lxdh = this.copyUserData.tel;
        this.formData.userId = this.copyUserData.id;
        this.formData.zjzl = '1';
      } else {
        this.formData.userId = this.copyUserData.id;
      }
    },
    // 新增验证码相关逻辑 start
    getNewVerCode() {
      this.identifyCode = '';
      this.makeCode(this.identifyCodes, 4);
    },
    // 生成随机数
    randomNum(min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },
    // 切换验证码
    refreshCode() {
      this.identifyCode = '';
      this.makeCode(this.identifyCodes, 4);
    },
    // 生成四位随机验证码
    makeCode(o, l) {
      for (let i = 0; i < l; i++) {
        this.identifyCode += this.identifyCodes[this.randomNum(0, this.identifyCodes.length)];
      }
    },
    // 新增验证码相关逻辑 end
    // 添加不动产权证号
    add() {
      this.formData.yyzh.push('');
    },
    // 删除不动产权证号
    deleteRow(index) {
      const yyzh = [...this.formData.yyzh];
      yyzh[index] = 'hidden';
      this.$set(this.formData, 'yyzh', yyzh);
    },
    last() {
      this.$emit('last', 0);
    },
    // 获取办事大厅地址
    getBsdtDetail() {
      const params = {
        officeCode: this.selectedBsdtCode
      };
      bsdtDetail(params).then((res) => {
        if (res.data && res.success) {
          const { data } = res;
          this.formData.yybsdtdz = data.address;
          this.formData.bsdtdh = data.dh;
        }
      });
    },
    //根据办事大厅获取可预约时间可预约数量
    getYyslAndYysj() {
      // 获取系统时间
      getSystemTime().then((res) => {
        // 获取预约时间
        const params = {
          djjg: this.selectedOrganizationsTitle,
          bsdt: this.selectedBsdtTitle,
          qssj: res.data
        };
        // console.log(params, '传给预约数量的参数');
        getYyysl(params).then((res) => {
          if (res && res.success) {
            const { data } = res;
            this.cacheYyyslData = data;
            this.appointmentDate = data.map((item) => item.date);
          }
        });
      });
    },
    // 获取预约时间段
    getSjdByOfficeCode() {
      getSjdByOfficeCode({ officeCode: this.selectedBsdtCode }).then((res) => {
        if (res.data && res.success) {
          this.appointmentTimes = [...res.data];
        }
      });
    },
    // 获取预约业务类型
    getYyxxConfigByDjjg() {
      getYyxxConfigByDjjg({
        djjg: this.selectedOrganizationsCode
      }).then((res) => {
        if (res && res.success) {
          const { data } = res;
          this.appointmentBusiness = [...data];
        }
      });
    },
    // 获取预约次数
    getAppointmentCount() {
      const result = this.cacheYyyslData;
      if (!result) return false;
      const { yysj, yyywlx, sjd } = this.formData;
      if (yysj && yyywlx && sjd) {
        // 找到对应的预约日期
        const date = result.find((item) => item.date === yysj);
        // 找到对应的业务类型
        const name = date.yyxxConfigDtoList.find((item) => item.name === yyywlx);
        // 找到对应的时间段
        const time = name.countVoList.find((item) => item.periodTime === sjd);
        const { count, surplus } = time;
        this.formData.yyysl = count;
        this.formData.kyysl = surplus;
      } else {
        this.formData.yyysl = '';
        this.formData.kyysl = '';
      }
    },
    // 获取预约事项详情
    getFlowId(value) {
      this.formData.yyFlowId = value[value.length - 1];
      let flowCode = '';
      for (let i = 0, len = this.appointmentMatter.length; i < len; i++) {
        const cur = this.appointmentMatter[i];
        const { flowSubDefConfigs } = cur || [];
        const yyFlow = flowSubDefConfigs.find((item) => item.id === this.formData.yyFlowId);
        if (yyFlow) {
          this.formData.yysx = yyFlow.name;
          flowCode = cur.flowCode;
          break;
        }
      }
      // 获取事项信息
      this.$refs.matter.showGuide(this.formData.yyFlowId);
      this.showMatter = true;
      this.showAddress(flowCode);
    },
    // 展示预约地址
    showAddress(flowCode) {
      const { yysx } = this.formData;
      if (!yysx) {
        this.formData.yybsdtdzck = '';
      } else {
        getWindowCode({
          flowCode,
          officeCode: this.selectedBsdtCode
        }).then((res) => {
          if (res && res.success) {
            this.formData.yybsdtdzck = this.formData.yybsdtdz + res.data;
          }
        });
      }
    },
    // 提交表单
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.saveAppointment();
        }
      });
    },
    // 预约
    saveAppointment() {
      // 处理证书模板
      // 1.过滤掉hidden数据
      let yyzh = this.formData.yyzh.filter((item) => item !== 'hidden');
      yyzh = yyzh.length > 1 ? yyzh : yyzh[0];
      delete this.formData.yyFlowIds;
      if (this.formData.code) {
        delete this.formData.code;
        delete this.formData.authCode; //不串authCode接口也报错
      }
      const params = {
        ...this.formData,
        yyzh,
        // ...this.userData,
        yyjg: this.selectedOrganizationsTitle,
        yybsdt: this.selectedBsdtTitle,
        orgCode: this.selectedOrganizationsCode,
        bsdtTitle: this.selectedBsdtTitle,
        sfyddbl: 0
      };
      saveAppointment(params).then((res) => {
        if (res && res.success) {
          const { data } = res;
          const appointMentData = {
            yybh: data.yybh,
            successNum: data.successNum,
            ...params
          };
          this.$emit('next', 2);
          this.$emit('sendData', appointMentData);
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.appointment-info {
  .header-tip {
    background-color: #f2f6fc;
    padding: 15px 20px;
    font-size: 14px;
    color: #333333;
    margin-bottom: 15px;
    font-weight: bold;
  }
  .tip {
    margin: 20px 0;
    padding: 15px 20px 15px 20px;
    background-color: #fff3dd;
    color: #e6a23c;
    font-size: 13px;
    border-radius: 8px;
  }
  .top-tab {
    height: 42px;
    border-bottom: 1px solid #e7e7e7;
    .tab-content {
      border-bottom: #0168b7 solid 1.5px;
      display: inline-block;
      height: 42px;
      line-height: 42px;
      padding: 0 20px;
      color: #0168b7;
      font-size: 16px;
    }
  }
  .info-wrap {
    background-color: #ffffff;
    margin-top: 20px;
    /deep/ .el-select,
    .el-cascader {
      width: 100%;
    }
    .el-row {
      margin-left: 10px !important;
    }
    .flex {
      justify-content: space-between;
    }
    .code {
      height: 40px;
      img {
        height: 100%;
      }
    }
  }
}
</style>
