export const zxTemplate = [
  {
    label: '***（***）***不动产证明***号'
  },
  {
    label: '***国用（***）第***号'
  },
  {
    label: '***房权证***字第***号'
  },
  {
    label: '其它'
  }
];
