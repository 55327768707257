import request from 'iebdc/utils/request';
import { REST_SERVICE } from 'iebdc/config';

// 获取办事大厅详情
export const bsdtDetail = (params) =>
  request({
    url: REST_SERVICE.uums.bsdtDetail,
    method: 'get',
    params,
  });
// 获取系统时间
export const getSystemTime = (params) =>
  request({
    url: REST_SERVICE.hall.getSystemTime,
    method: 'get',
    params,
  });
// 获取已经预约数量
export const getYyysl = (params) =>
  request({
    url: REST_SERVICE.hotService.appointment.getNewYyysl,
    method: 'get',
    params,
  });
// 获取已经预约数量(是时间段吧)
export const getSjdByOfficeCode = (params) =>
  request({
    url: REST_SERVICE.hotService.appointment.getSjdByOfficeCode,
    method: 'get',
    params,
  });
// 获取已经预约数量（？？应该是获取预约业务类型）
export const getYyxxConfigByDjjg = (params) =>
  request({
    url: REST_SERVICE.hotService.appointment.getYyxxConfigByDjjg,
    method: 'get',
    params,
  });
// 获取办事指南
export const showGuide = (params) =>
  request({
    url: REST_SERVICE.hotService.appointment.showGuide,
    method: 'get',
    params,
  });
export const showFiles = (params) =>
  request({
    url: REST_SERVICE.hotService.appointment.showFiles,
    method: 'get',
    params,
  });
export const getWindowCode = (params) =>
  request({
    url: REST_SERVICE.hotService.appointment.getWindowCode,
    method: 'get',
    params,
  });
// 保存预约信息
export const saveAppointment = (params) =>
  request({
    url: REST_SERVICE.hotService.appointment.saveAppointment,
    method: 'post',
    data: params,
  });
